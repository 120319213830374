import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const SecureSoftwareDevelopmentLifeCyclePageTemplate = ({
  seo,
  hero,
  summarysection,
  download,
  contactus,
  aboutsection,
  tsdpsection,
  ssdcsection,
  fixedimagesection
}) => (
  <div>
    <SEO title={seo.title} description={seo.description} />
    <HeroOther data={hero} />
    <section className="section" style={{ background: `${summarysection.sectionbg}`}}>
      <div className="container">
        <div>
          <Title title={summarysection.title} color={summarysection.textcolor} />
          <SubTitle title={summarysection.description} color={summarysection.textcolor} margin="1rem 0"/>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-9">
            <SubTitle title={download.title} color={download.textcolor} />
          </div>
          <div className="column is-3">
            <a href={download.buttonlink} >
              <button style={{fontSize: 20, width: "100%", background: "#53c5d1" }}>{download.buttontxt}</button>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="section" style={{ background: `${aboutsection.sectionbg}`}}>
      <div className="container">
        <div style={{ marginTop: "2rem"}}>
          <Title title={aboutsection.title} color={aboutsection.textcolor} />
          <Title title={aboutsection.secondtitle} color={aboutsection.textcolor}/>
          <SubTitle title={aboutsection.subheader} color={aboutsection.textcolor} margin="1.5rem 0"/>
          <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="1rem 0" />
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-9">
            <SubTitle title={contactus.title} />
          </div>
          <div className="column is-3">
            <a href={contactus.buttonlink} >
              <button style={{fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
            </a>
          </div>
        </div>
        <div className="columns">
          <div className="column is-6"></div>
          <div className="column is-6">
            <div style={{maxWidth: 150, height: 1}}>
              <PreviewCompatibleImage imageInfo={contactus.image} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section" style={{ background: `${tsdpsection.sectionbg}`}}>
      <div className="container">
        <div style={{ marginTop: "6rem"}}>
          <Title title={tsdpsection.title} color={tsdpsection.textcolor} />
          <Title title={tsdpsection.secondtitle} color={tsdpsection.textcolor} />
          <Descriptions descriptions={tsdpsection.descriptions1} color={tsdpsection.textcolor} margin="1rem 0" />
          <SubTitle title={tsdpsection.subheader2} color={tsdpsection.textcolor} margin="1.5rem 0"/>
          <Descriptions descriptions={tsdpsection.descriptions2} color={tsdpsection.textcolor} margin="1rem 0" />
          <div className="columns custom-columns">
            <div className="column is-4 mobile-center">
              <div style={{maxWidth: 300, marginTop: 100, height: 10}}>
                <PreviewCompatibleImage imageInfo={tsdpsection.image} />
              </div>
            </div>
            <div className="column is-8">
              <Descriptions descriptions={tsdpsection.sidedescriptions} color={tsdpsection.textcolor} margin="1rem 0" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section" style={{ margin: "5rem 0"}}>
      <div className="container">
        <div className="columns" >
          <div className="column is-8">
            <Title title={ssdcsection.title} />
            <Title title={ssdcsection.secondtitle} />
            <SubTitle title={ssdcsection.subheader}  margin="1.5rem 0"/>
            <Descriptions descriptions={ssdcsection.sidedescriptions} />
          </div>
          <div className="column is-4">
            <div style={{maxWidth: 400, margin: "auto"}}>
              <PreviewCompatibleImage imageInfo={ssdcsection.image} />
            </div>
          </div>
        </div>
        <Descriptions descriptions={ssdcsection.descriptions} />
      </div>
    </section>
    <AboveFooter data={fixedimagesection} height="300px" />
  </div>
)

SecureSoftwareDevelopmentLifeCyclePageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  summarysection: PropTypes.object,
  download: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object, 
  tsdpsection: PropTypes.object,
  ssdcsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const SecureSoftwareDevelopmentLifeCyclePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SecureSoftwareDevelopmentLifeCyclePageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        summarysection={frontmatter.summarysection}
        download={frontmatter.download}
        aboutsection={frontmatter.aboutsection}
        contactus={frontmatter.contactus}
        tsdpsection={frontmatter.tsdpsection}
        ssdcsection={frontmatter.ssdcsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

SecureSoftwareDevelopmentLifeCyclePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SecureSoftwareDevelopmentLifeCyclePage

export const pageQuery = graphql`
  query SecureSoftwareDevelopmentLifeCyclePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ssdlc-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt 
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align 
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        summarysection {
          sectionbg
          textcolor
          title
          description
        }
        download {
          textcolor
          title
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
        }
        contactus {
          title
          buttontxt
          buttonlink
          image {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tsdpsection {
          sectionbg
          textcolor
          title
          subheader1
          descriptions1
          subheader2
          descriptions2
          image {
            childImageSharp {
              fluid(maxWidth: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          sidedescriptions
        }
        ssdcsection {
          title
          secondtitle
          subheader
          descriptions
          sidedescriptions
          image {
            childImageSharp {
              fluid(maxWidth: 460, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
